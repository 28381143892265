import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'app-root': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

type ClientProps = {
    isOpen: boolean
}

const Client: FC<ClientProps> = props => {

    const {
        isOpen
    } = props;

    const [css, setCss] = useState<HTMLLinkElement[]>([]);
    const [scripts, setScripts] = useState<HTMLScriptElement[]>([]);

    const loadScripts = useCallback(() => {
        (window as any).NitroConfig = {
            configurationUrl: 'https://haddoz.net/NitroU/configuration.json',
            sso: "1279ee710463ba345ad029b61970dfefc11d29bd32e6f0be"
        };

        const assets = ['runtime.js', 'polyfills.js', 'vendor.js', 'main.js'];
        const now = Date.now();
        const _scripts: HTMLScriptElement[] = []
        for (const i in assets) {
            const script = document.createElement('script');
            script.src = "https://haddoz.net/NitroU/" + assets[i] + "?" + now;

            _scripts.push(script);
            document.body.appendChild(script);
        }

        setScripts(_scripts);
    }, [])

    const loadCss = useCallback(() => {
        const cssAssets = ['styles_atlanta_5.css', 'atlanta_c.css'];
        const now = Date.now();
        const _css: HTMLLinkElement[] = [];
        for (const i in cssAssets) {
            const link = document.createElement('link');
            link.rel = "stylesheet";
            link.id = "nitro-s-" + i
            link.href = "https://haddoz.net/NitroU/" + cssAssets[i] + "?" + now;
            document.head.appendChild(link);
            _css.push(link);
        }
        setCss(_css);
    }, [])

    const unloadCss = useCallback(() => {
        css.forEach(val => {
            val.remove();
        });
        setCss([]);
    }, [css])

    useEffect(() => {
        if (scripts.length === 0) loadScripts();
        if (isOpen && css.length === 0) loadCss();

        if (!isOpen && css.length > 0) unloadCss();
    }, [isOpen, scripts, css, loadScripts, loadCss, unloadCss]);

    const history = useHistory();
    
    const goToPreviousPath = useCallback(() => {
        history.goBack()
    },[history]);

    const style = { display: isOpen ? "block" : "none" };
    return (<>
        <div style={style}>
            <button onClick={goToPreviousPath} className="rounded-button purple-button no-border" style={{ position: "absolute", zIndex: 99 }}>Web</button>
            <app-root />
        </div>
    </>);
}
export default Client;