import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArticleCategory, ArticleList } from "../../../types/Articles";
import { User } from "../../../types/User";
import Api from "../../core/Api";
import { Loader } from "../loader/Loader";
import ArticleCats from "./subcomponents/ArticleCats";
import ArticlesSlider from "./subcomponents/ArticlesSlider";
import BigJumbotron from "./subcomponents/BigJumbotron";
import { LoginHome } from "./subcomponents/LoginHome";

type HomeProps = {
    user: User,
}

const Home: FC<HomeProps> = props => {

    const {
        user
    } = props;

    const {
        t
    } = useTranslation();

    const [isLoading, loadingState] = useState(true);
    const [articles, setArticles] = useState<ArticleList[]>([]);
    const [cats, setCategories] = useState<ArticleCategory[]>([]);
    const [artByCat, setArticlesByCat] = useState<ArticleList[]>([]);
    const [category, setCategory] = useState<number>(0);

    const loadArticles = useCallback(async () => {

        const articles: {
            "artByCat": ArticleList[],
            "articles": ArticleList[],
            "cats": ArticleCategory[]
        } = await Api.getInstance().get('/articles/home');

        setArticles(articles.articles);
        setCategories(articles.cats);
        setArticlesByCat(articles.artByCat);

        loadingState(false);

    }, [setArticles, loadingState, setCategories, setArticlesByCat])

    const updateCategory = useCallback(async (cat: number) => {
        if (cat === category) return;

        setCategory(cat);

        const articles: ArticleList[] = await Api.getInstance().get('/articles/home/cat', { id: cat });

        setArticlesByCat(articles);
    }, [category, setCategory, setArticlesByCat]);

    useEffect(() => {
        loadArticles();
    }, [loadArticles]);

    if (isLoading) return (<Loader />);

    return (<>


        {user.id === 0 ?
            <LoginHome />
            :
            <>
                <div className="col-12 d-sm-block d-md-none">
                    <Link className="btn btn-success btn-lg" style={{ width: '100%', marginBottom: '10px' }} to="/hotel">{t('pages.home.button_enter')}</Link>
                </div>
            </>
        }
        <div className="row d-block d-sm-block d-md-none">
            <ArticleCats articles={artByCat} cats={cats} boxSize={12} updateCategory={updateCategory} />
        </div>
        <div className="row d-none d-sm-none d-md-block clearfix">
            <ArticlesSlider articles={articles} />
            <ArticleCats articles={artByCat} cats={cats} boxSize={4} updateCategory={updateCategory} />
        </div>

        {user.id === 0 ? <BigJumbotron /> : <></>}


    </>);
}

export default Home;