import { AVATAR_IMAGER } from '../../config/WebConfig';
import HttpUtil from './HttpUtil';

export type FigureParams = {
    action?: string | 'std'
    direction?: number | 2
    head_direction?: number | 2
    gesture?: string | 'std'
    size?: string | 'n'
    img_format?: string | 'png'
    frame?: number | 3
    headonly?: boolean | false

}


export function avatar(look: string| undefined, params: (string | FigureParams)): string {
    if(!look){
        return "";
    }
    let queryString = "";
    if (typeof params === "string") {
        queryString += params;
    }
    else {
        queryString += HttpUtil.httpBuildQuery(params);
    }
    return AVATAR_IMAGER + "?figure=" + look + "&" + queryString;
}