import React, { FC, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type RegisterProps = {
    isOpen: boolean;
    handleClose: () => void;
}



export const Register: FC<RegisterProps> = props => {

    const {
        isOpen,
        handleClose
    } = props;

    const {
        t
    } = useTranslation();

    const sendForm = useCallback(async (form: React.FormEvent<HTMLFormElement>) => {
        //form.preventDefault();

        console.log(' send form!!');
    }, []);

    const now = (new Date().getUTCFullYear() - 8);
    const years = Array(now - (now - 114)).fill('').map((v, idx) => now - idx);

    const months = [...Array.apply(0, Array(12))].map((_, i) => { return (new Date(2009, i, 10)).toLocaleString('default', { month: 'long' }) });

    return (<><Modal show={isOpen} onHide={handleClose} dialogClassName="register-modal" centered>
        <Modal.Body >
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <Modal.Title style={{ fontSize: "1.25rem", marginBottom: "15px" }}>{t('register.title')}</Modal.Title>
            <form method="post" onSubmit={sendForm} autoComplete={"off"}>
                <div className="form-group">
                    <label>{t('register.form.fields.username')}</label>
                    <input type="text" name="username" className="form-control" required={true} />
                </div>
                <div className="form-group">
                    <label>{t('register.form.fields.email')}</label>
                    <input type="email" name="email" className="form-control" required={true} />
                    <small style={{ color: '#FFF' }} className="form-text">{t('register.form.fields.email_text')}</small>
                </div>
                <div className="row" style={{ marginBottom: 0 }}>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>{t('register.form.fields.password')}</label>
                            <input type="password" name="password" className="form-control" required={true} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>{t('register.form.fields.repassword')}</label>
                            <input type="password" name="repassword" className="form-control" required={true} />
                        </div>
                    </div>


                </div>
                <div className="form-group" id="input-register-bdate">
                    <label>{t('register.form.fields.bdate')}</label>
                    <div className="input-group" style={{ width: '100%', boxShadow: 'none' }}>
                        <select name="bday" className="form-control custom-select" defaultValue={0} required={true}>
                            <option value={0} key={-1} disabled>{t('register.form.fields.bday')}</option>
                            {
                                [...Array(32)].map((_, i) => (

                                    i > 0 ? <option key={"d" + i} value={i}>{i}</option> : ""
                                ))
                            }
                        </select>
                        <span className="input-group-addon" style={{ width: '0px', paddingLeft: '0px', paddingRight: '0px', border: 'none' }} />
                        <select name="bmonth" className="form-control custom-select" defaultValue={0} required={true}>
                            <option value={0} key={-1} disabled>{t('register.form.fields.bmonth')}</option>
                            {
                                months.map((val:string, i:number) => (
                                    <option key={"m" + i} value={(i+1)}>{val}</option>
                                ))
                            }
                        </select>
                        <span className="input-group-addon" style={{ width: '0px', paddingLeft: '0px', paddingRight: '0px', border: 'none' }} />
                        <select name="byear" className="form-control custom-select" defaultValue={0} required={true}>
                            <option value={0} key={-1} disabled>{t('register.form.fields.byear')}</option>
                            {
                                years.map((v, i) => (
                                    <option key={v.toString()} value={v}>{v}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <button type="submit" className="btn btn-success btn-lg" style={{ width: '100%', fontSize: '22px' }}>{t('register.form.fields.button')}</button>
                <small style={{ marginTop: '10px' }} className="form-text">{t('register.tc.1')} <a href="#a" style={{ color: '#FFF', textDecoration: 'underline' }}>{t('register.tc.2')}</a>.</small>

            </form>
        </Modal.Body>
    </Modal></>);
}