
export function animateCSS(element: string | Element, animationName: string): Promise < void> {
    return new Promise((resolve, reject) => {
        const node = ((typeof element === 'string') ? document.querySelector(element) : element);

        if (typeof node === undefined || node === null) {
            reject('Invalid node');
            return;
        }


        node.classList.add('animated', animationName)

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName)
            node.removeEventListener('animationend', handleAnimationEnd)

            resolve();
        }

        node.addEventListener('animationend', handleAnimationEnd)
    });
}