export type User = {
    id: number;
    username?:string;
    look?:string;
    motto?:string;
    currencies?: UserCurrency[];
    lastOnline?: number;
    lastOnline1?: number;
}

export type UserCurrency = {
    amount: number;
    type: number;
}

export const defaultUser: User = {
    id: 0,
}
/*export const defaultUser: User = {
    id: 1,
    username: "SergioT",
    look: "ha-3272-1191-1408.ch-3001-1191-1184.sh-290-1220.hr-828-37.hd-990000457-1.he-3081-65.cc-61274158-96-1210.lg-50858737-96",
    last_online: ""
}*/