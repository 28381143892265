import CryptoJS from "crypto-js";
import Cookies from 'universal-cookie';
import { API_URL } from "../../config/WebConfig";
import { btoe, CryptoJSAesEncrypt } from "../util/CryptoUtil";
import axios, { AxiosInstance } from 'axios';
import AtlantaApp from "../AtlantaApp";

class Api {
    private static instance: Api = new Api();

    public static getInstance(): Api {
        return Api.instance;
    }

    private readonly requestOptions: RequestInit;

    private readonly cookies: Cookies;

    private readonly importantHeaders = ['ATLANTA_SSID', 'ATLANTA_UID', 'ATLANTA_STI']

    private readonly axiosInstance: AxiosInstance;

    constructor() {
        this.cookies = new Cookies();
        this.requestToken();

        this.requestOptions = {};
        
        this.axiosInstance = axios.create();
    }

    private reloadCookies() {
        this.axiosInstance.defaults.timeout = 4000;
        this.axiosInstance.defaults.headers.common = {};
        delete this.requestOptions.body;
        this.axiosInstance.defaults.headers.post['Content-Type']  = 'application/json';
        this.importantHeaders.forEach(e => {
            const cookie = this.cookies.get(e)
            if (!cookie)
                return;

            this.axiosInstance.defaults.headers.common[e] = cookie;
        })

        this.axiosInstance.defaults.headers.common['ATLANTA_WEB'] = localStorage.getItem('ATLANTA_WEB');
        this.axiosInstance.defaults.headers.common['ATLANTA_FP'] = AtlantaApp.getInstance().fingerPrint;
    }

    private requestToken() {
        const cat = localStorage.getItem('ATLANTA_WEB');

        if (!cat) {
            const salt = CryptoJS.lib.WordArray.random(40);
            localStorage.setItem('ATLANTA_WEB', salt.toString());
        }
    }

    async get(url: string, params: any = {}): Promise<any> {

        if (url.startsWith('/')) {
            url = API_URL + url;
        }
        this.reloadCookies();

        const result = await this.axiosInstance.get(url, {params});

        return result.data;
        /*this.requestOptions.method = 'get';

        const req: Response = await fetch(url, this.requestOptions);
        return req.json();*/
    }

    async post(url: string, body: any): Promise<any> {
        if (url.startsWith('/')) {
            url = API_URL + url;
        }

        this.reloadCookies();

        const result = await this.axiosInstance.post(url, JSON.stringify(body))
        return result.data;
    }

    async postEncode(url: string, body: any): Promise<any> {
        if (url.startsWith('/')) {
            url = API_URL + url;
        }

        this.reloadCookies();

        const encode = CryptoJSAesEncrypt(localStorage.getItem('ATLANTA_WEB'), JSON.stringify(body));

        const result = await this.axiosInstance.post(url, JSON.stringify({ rq: btoe(encode) }));
        return result.data;
    }




}
export default Api;