import React, { FC } from 'react';
import './loader.css';


export const Loader: FC<{}> = props => {
    return (
        <div id={'preloader-full'}>
            <div id="loader"></div>
        </div>
    )
}