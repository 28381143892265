export const AVATAR_IMAGER: string = 'https://habbo.city/habbo-imaging/avatarimage';

//export const API_URL: string = 'http://localhost:4000/api/v1';


export const WALLET_ICON_URL: string = 'https://assets.atlantaserver.me/assets/wallet/haddoz/';


export const HOTEL = {
    SHORTNAME: "XKekos",
    FULLNAME: "Xkekos Hotel",
    GALLERY_PATH: "https://atlantapictures.net/public/purchased/xkekos/"
}
export const API_URL: string = 'https://haddozws.atlantaserver.me:2096/api/v1';