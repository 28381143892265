import React ,{ FC, useCallback, useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { User } from "../../../../types/User";
import AtlantaApp from "../../../AtlantaApp";

type HeaderNavProps = {
    user: User
}

const HeaderNav: FC<HeaderNavProps> = props => {
    const {
        user
    } = props;

    const location = useLocation();

    const {
        t
    } = useTranslation();

    const [currentPath, setCurrentPath] = useState(location.pathname);

    const openRegister = useCallback(() => {
        AtlantaApp.getInstance().openRegister();
    }, []);

    const getNavLinkClass = useCallback((path: string) => {
        if (path === "/" && currentPath === path)
            return 'active';

        return path !== "/" && currentPath.indexOf(path) > -1 ? 'active' : '';
    }, [currentPath])

    useEffect(() => {
        const currentLocation = location.pathname;        
        setCurrentPath(currentPath => (currentPath === currentLocation) ? currentPath: currentLocation);
    }, [location, setCurrentPath])

    return (<>
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Container>
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to="/" className={getNavLinkClass("/")}>{t('header.menu.home')}</Nav.Link>
                            <NavDropdown title="Comunidad" id="collasible-nav-dropdown" className={getNavLinkClass("/community")}>
                                <NavDropdown.Item as={Link} to="/community/gallery">{t('header.menu.community.gallery')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/community/articles">{t('header.menu.community.articles')}</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={t('header.menu.teams.title')} id="collasible-nav-dropdown" className={getNavLinkClass("/teams")}>
                                <NavDropdown.Item as={Link} to="/teams/staffs">{t('header.menu.teams.staff')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/teams/inters">{t('header.menu.teams.inters')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/teams/gamemasters">{t('header.menu.teams.gm')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/teams/bots">{t('header.menu.teams.bots')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/teams/alphas">{t('header.menu.teams.alphas')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/teams/colaboradores">{t('header.menu.teams.colab')}</NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                        <Nav>
                            {
                                user.id > 0 ? <Nav.Link onClick={() => { }}>{t('header.menu.logout')}</Nav.Link> :
                                    <Nav.Link onClick={openRegister}>{t('header.menu.register')}</Nav.Link>
                            }
                        </Nav>

                    </Container>
                </Navbar.Collapse>
            </Navbar>
        </>
    </>)
}

export default HeaderNav;