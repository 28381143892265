import i18n from 'i18next';
import es from './lang/es/translation.json'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
    es: {
        translation: es
    }
} as const;

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'es',
        lng: 'es',
        resources,
    });

export default i18n