export function getCurrencyName(type:number){
    /*DUCKETS = 0,
        MAD_MONEY = 3,
        DIAMONDS = 5,
        //DIAMONDS_2 = 105,
        SEA_SHELLS = 101,
        PEANUTS = 102,
        STARS = 103,
        CLOUDS = 104*/

    switch(type){
        case -1:
            return 'coins';
        case 0:
            return 'duckets';
        case 3:
            return 'mad_money';
        case 5:
            return 'diamonds';
        case 101:
            return 'sea_shells';
        case 102:
            return 'peanuts';
        case 103:
            return 'stars';
        case 104:
            return 'clouds';
    }
}