import React, { FC, useEffect } from "react";
import { User } from "../../../types/User";
import AtlantaApp from "../../AtlantaApp";

type HotelProps = {
    user: User
}

const Hotel: FC<{}> = props => {

    useEffect(() => {
        AtlantaApp.getInstance().openClient(true);

        return () => {
            AtlantaApp.getInstance().openClient(false);
        }
    }, [])

    return (<></>);
}

/*class Hotel extends Component<HotelProps>{
    
    componentDidMount(){
        AtlantaApp.getInstance().openClient(true);
    }

    componentWillUnmount(){
        AtlantaApp.getInstance().openClient(false);
    }

    render(){

        const {
            user
        } = this.props;
        
        return (<></>);
    }
}*/
export default Hotel;