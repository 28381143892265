import React, { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArticleCategory, ArticleList } from "../../../../types/Articles";
import Api from "../../../core/Api";
import TimeAgoSection from "../../../sections/TimeAgoSection";


const ArticlesList: FC<{}> = props => {

    const [articleList, setArticleList] = useState<ArticleList[]>([]);
    const [categories, setCategories] = useState<ArticleCategory[]>([]);

    const [lastSearch, setLastSearch] = useState<{ str: string, categoryId: number, page: number }>();

    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

    const getCategories = useCallback(async () => {
        const cats: ArticleCategory[] = await Api.getInstance().get('/articles/articlescats');
        if (cats) setCategories(cats);
    }, [setCategories]);

    const getList = useCallback(async (str: string, categoryId: number, page: number, cleaned: boolean = true) => {
        const newArticles: ArticleList[] = await Api.getInstance().get('/articles/articleslist', { str, categoryId, page });

        if (newArticles) {

            if (cleaned) setArticleList(newArticles);
            else setArticleList(articles => articles.concat(newArticles));

            if (newArticles.length > 8) setButtonIsDisabled(false);
            else setButtonIsDisabled(true);
        }

        setLastSearch({
            str, categoryId, page
        })
    }, [setLastSearch, setArticleList, setButtonIsDisabled])

    const loadMore = useCallback(async () => {
        await getList(lastSearch.str, lastSearch.categoryId, lastSearch.page + 1, false);
    }, [lastSearch, getList])

    const sendForm = useCallback(async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault();

        const elements: HTMLFormControlsCollection = form.currentTarget.elements;
        const title = (elements.namedItem("title") as HTMLInputElement).value;
        const category = (elements.namedItem("category") as HTMLInputElement).value;

        await getList(title, parseInt(category), 0);

    }, [getList])



    useEffect(() => {
        getList("", 0, 0);
        getCategories();
    }, [getList, getCategories])

    return (<>

        <div className="col-md-4 col-sm-12">
            <form method="post" onSubmit={sendForm}>
                <h4>Filtros</h4>
                <div className="form-group">
                    <label>Título:</label>
                    <input type="text" name="title" className="form-control" />
                </div>
                <div className="form-group">
                    <label>Categoria:</label>
                    <select className="custom-select" name="category" id="articles-category">
                        <option value={0} key={0}>Todas</option>
                        {
                            categories.map(val => (
                                <option value={val.id} key={val.id}>{val.name}</option>
                            ))
                        }
                    </select>
                </div>
                <button type="submit" className="btn btn-outline-success">Filtrar</button>
            </form>
        </div>
        {/* START MOBILE FIXER */}
        <div className="d-block d-sm-block d-md-none" style={{ height: '30px', width: '100%' }} />
        {/* END MOBILE FIXER */}
        <div className="col-md-8 col-sm-12">
            <h4>Notícias</h4>
            <div className="row allarticles">
                {
                    articleList.map(val => (
                        <div className="col-md-6 col-sm-12" key={val.link}>
                            <Link className="hall-item" to={`/community/articles/${val.link}`}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="image-window" style={{ background: `url(${val.headerImage}) center` }} />
                                        <small className="text-muted" style={{ float: 'right' }}><TimeAgoSection date={(val.time * 1000)} /></small>
                                        <div className="title">{val.title}</div>
                                        <div className="desc">{val.summary}</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                }
                {
                    !buttonIsDisabled ? <button onClick={loadMore} className="btn btn-outline-success btn-lg" style={{ width: '100%' }} type="button">Mais Notícias</button> : <></>
                }

            </div>
        </div>

    </>)
}

export default ArticlesList;