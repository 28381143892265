import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Article, ArticleList } from "../../../types/Articles";
import { User } from "../../../types/User";
import Api from "../../core/Api";
import { Loader } from "../loader/Loader";
import NotFound from "../notfound/NotFound";
import ArticleBody from "./subcomponents/ArticleBody";
import ArticlesList from "./subcomponents/ArticlesList";
import ArticlesNav from "./subcomponents/ArticlesNav";

type ArticlesProps = {
    user: User;
}

const Articles: FC<ArticlesProps> = props => {
    const {
        user
    } = props;

    const params = useParams();

    const [article, setArticle] = useState<Article>(null);
    const [isLoading, setLoading] = useState(true);
    const [articlesNav, setArticlesNav] = useState<ArticleList[]>([]);
    const [navPage, setNavPage] = useState(1);
    const [currentPath, setCurrentPath] = useState("");
    const [elementsLoad, setElementsLoad] = useState(0);

    const elementsCount = params['title'] ? 2 : 1;

    const [notFound, setNotFound] = useState(false);

    const ref = useRef<HTMLDivElement>(null);


    const loadArticle = useCallback(async () => {
        const article: Article = await Api.getInstance().get("/articles/article/" + params['title']);

        if (!article) setNotFound(true);

        setArticle(article);
        setElementsLoad(t => (t + 1));
    }, [params, setArticle, setElementsLoad]);

    const loadArticleNav = useCallback(async (page: number) => {

        const articles: ArticleList[] = await Api.getInstance().get("/articles/articlesnav/" + page);

        if (!articles) return;

        setArticlesNav(currentArticles => currentArticles.concat(articles));
        setElementsLoad(elementsLoad + 2);
    }, [setArticlesNav, setElementsLoad, elementsLoad]);

    const loadMoreArticlesNav = useCallback(async () => {
        await loadArticleNav(navPage);
        setNavPage(navPage + 1);
    }, [loadArticleNav, navPage, setNavPage])

    const loadArticlesList = useCallback(() => {
        setElementsLoad(1);
    }, [setElementsLoad]);

    useEffect(() => {
        const title = params['title'];
        if (elementsCount === elementsLoad) {
            setLoading(false);
        }

        if (currentPath !== title) {
            if (title) {
                if (ref && ref.current)
                    ref.current.scrollIntoView({ behavior: 'smooth' });

                loadArticle();

                if (articlesNav.length === 0) loadArticleNav(0);

            }
            else loadArticlesList();

            setCurrentPath(title);
        }

    }, [params, loadArticle, loadArticleNav, articlesNav, currentPath, elementsCount, elementsLoad, loadArticlesList]);


    if (notFound) return (<NotFound />)

    if (isLoading || (params['title'] && !article)) return (<Loader />);

    return (<>
        <div className="row" ref={ref}>
            {params['title'] ?
                <>
                    <ArticleBody article={article} user={user} />
                    <div className="d-block d-sm-block d-md-none" style={{ height: '30px', width: '100%' }} />
                    <ArticlesNav articles={articlesNav} buttonLoadMore={loadMoreArticlesNav} /></> :
                <ArticlesList />
            }
        </div>
    </>)
}

export default Articles;