import React, { FC, useCallback, useEffect, useState } from "react";
import { GalleryPhoto } from "../../../types/Gallery";
import Api from "../../core/Api";
import { Loader } from "../loader/Loader";
import GalleryItem from "./subcomponents/GalleryItem";

const Gallery: FC<{}> = props => {

    const [galleryItems, setGalleryItems] = useState<GalleryPhoto[]>([]);
    const [isLoading, setLoading] = useState(true);

    let page = 0;

    const loadGallery = useCallback(async () => {
        const gItems: GalleryPhoto[] = await Api.getInstance().get('/gallery/items', { page });

        if (gItems) {
            setGalleryItems(items => items.concat(gItems));
        }
        setLoading(false);

        page++;
    }, [setGalleryItems, page])



    useEffect(() => {
        loadGallery();
    }, [loadGallery]);


    const scrollPage = useCallback((_: Event) => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            loadGallery();
        }
    }, [loadGallery]);

    useEffect(() => {
        window.addEventListener('scroll', scrollPage);

        return () => {
            window.removeEventListener('scroll', scrollPage);
        }
    }, [scrollPage]);

    if (isLoading) return (<Loader />)

    return (<>

        <div className="row" >
            <div className="col-12">
                <h4>Galeria</h4>
                <div className="row">
                    {
                        galleryItems.map((val, i) => (
                            <GalleryItem photoData={val} key={i} />
                        ))
                    }

                </div>
            </div>
        </div>


    </>)
}

export default Gallery;