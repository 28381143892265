import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArticleList } from "../../../../types/Articles";

type ArticlesSliderProps = {
    articles: ArticleList[];
}

const ArticlesSlider = (props: ArticlesSliderProps): JSX.Element => {
    const {
        articles
    } = props;

    const {
        t
    } = useTranslation();

    return (<>
        <div className="col-8" style={{ float: "left" }}>
        <h4>{t('pages.home.articles.slider_title')}</h4>
            <Carousel className="news-carousel" >
                {
                    articles.map(val => (
                        <Carousel.Item key={val.link} as={Link} to={`/community/articles/${val.link}`} style={{ backgroundImage: `url(${val.headerImage})` }}>

                            <Carousel.Caption>
                                <h5>{val.title}</h5>
                                <p>{val.summary}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </div>
    </>)
}
export default ArticlesSlider;