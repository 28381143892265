import { User, UserCurrency } from "../../../../types/User";
import React, { FC } from 'react';
import { getCurrencyName } from "../../../util/CurrencyUtil";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WALLET_ICON_URL } from "../../../../config/WebConfig";
import TimeAgoSection from "../../../sections/TimeAgoSection";
import { avatar } from "../../../util/FigureUtil";

type HeaderUserProps = {
    user: User;
}

const HeaderUser: FC<HeaderUserProps> = props => {
    const {
        user
    } = props;

    const {
        t
    } = useTranslation();
    const generateWallet = user.currencies.map((currency: UserCurrency, index) => {
        const style = (user.currencies.length - 1 === index || index >= 6) ? { borderBottomRightRadius: '4px' } : {};
        const currencyName = getCurrencyName(currency.type);
        if (index === 7) return (<></>);
        return (
            <div className="variable" key={currency.type} style={style}><div className="icon" style={{ backgroundImage: `url(${WALLET_ICON_URL}/${currency.type}.png)` }} /><span>{currency.amount}</span> {t(`header.user.currency.${currencyName}`)}</div>
        )
    })
    return (<>
        <div className="col-md-6 d-none d-sm-none d-md-block" style={{ textAlign: 'center' }}>
            <Link to={"/"}>
                <img className="logo" alt="logo" style={{ marginTop: '60px' }} src="images/logo.gif" />
            </Link>

            <Link to="/hotel" className="rounded-button large-button default-cms-button">{t('header.user.button_enter')}</Link>
        </div>
        <div className="col-md-6 col-sm-8">
            <div className="row justify-content-md-center">
                <div className="col-md-7 col-sm-7">
                    <div className="my-info">
                        <div className="gray-box">
                            <div className="white-box">{t('header.user.welcome_title')}<br /><span className="username">{user.username}</span>!</div>
                            <div className="white-box last-login">{t('header.user.last_access')} <TimeAgoSection date={(user.lastOnline) > 0 ? user.lastOnline * 1000 : Date.now()} /></div>
                            <div className="avatar-area">
                                <div className="avatar" style={{ backgroundImage: `url(${avatar(user.look, { size: 'b', head_direction: 3, gesture: 'sml' })}` }} />
                            </div>
                            <div className="white-inverted-box">{user.motto}&nbsp;</div>
                        </div>
                        <div className="wallet">
                            {generateWallet}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default HeaderUser;