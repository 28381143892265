import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ArticleList } from "../../../../types/Articles";
import TimeAgoSection from "../../../sections/TimeAgoSection";

type ArticlesNavProps = {
    articles: ArticleList[],
    buttonLoadMore: () => void
}

const ArticlesNav: FC<ArticlesNavProps> = props => {

    const {
        articles,
        buttonLoadMore
    } = props;
    return (<>
        <div className="col-md-4 col-sm-12">
            <h4>Outras Notícias</h4>
            <div className="list-group articles">
                {
                    articles.map(value => (
                        <Link key={value.link} to={`/community/articles/${value.link}`} className="list-group-item list-group-item-action hall-item">
                            <div className="image-window" style={{ background: `url(${value.headerImage}) center` }} />
                            <small className="text-muted" style={{ float: 'right' }}><TimeAgoSection date={(value.time * 1000)} /></small>
                            <div className="title">{value.title}</div>
                            <div className="desc">{value.summary}</div>
                        </Link>
                    ))
                }

            </div><br />
            <button onClick={buttonLoadMore} className="btn btn-outline-success btn-lg" style={{ width: '100%' }} type="button">Mais Notícias</button><br />
            <Link to={"/community/articles"} className="btn btn-outline-danger btn-lg" style={{ width: '100%', marginTop:"5px" }}>Todas las noticias</Link>
        </div>

    </>)
}

export default ArticlesNav;