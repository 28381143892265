import React, { FC } from "react";
import { Link } from "react-router-dom";
import { HOTEL } from "../../../../config/WebConfig";
import { GalleryPhoto } from "../../../../types/Gallery";
import TimeAgoSection from "../../../sections/TimeAgoSection";
import { avatar } from "../../../util/FigureUtil";

type GalleryItemProps = {
    photoData: GalleryPhoto;
}

const GalleryItem: FC<GalleryItemProps> = props => {
    const {
        photoData
    } = props;

    const imagePath = HOTEL.GALLERY_PATH + photoData.id + ".png";
    
    return (<>
        <div className="col-md-3 col-sm-12">
            <div className="card gallery-picture">
                <div className="heading" style={{ backgroundImage: `url(${imagePath})` }}>
                    <span className="badge badge-dark">
                        <i className="far fa-clock" /> <TimeAgoSection date={(photoData.time * 1000)}/>
                    </span>
                    <div className="writer" style={{ backgroundImage: `url(${avatar(photoData.user.look, {size:'l', head_direction: 3, gesture: 'sml'})})` }} />
                    <div className="information">
                        Por <Link to={`/profile/${photoData.user.username}`}>{photoData.user.username}</Link>
                    </div>
                </div>
                <div className="options">
                    <a href="#" data-id="A1"><i className="fas fa-thumbs-up" /> Me gusta ({photoData.likeCount}) </a>
                     
                    <a href="#"><i className="fas fa-thumbs-down" /> No me gusta ({photoData.disLikeCount}) </a>
                </div>
            </div>
        </div>
    </>)
}

export default GalleryItem;