import { animateCSS } from "./AnimateUtil";


export function checkInput(input): boolean {
    return !input || input.value.length === 0;
}

export function inputError(input) {
    animateCSS(input, 'shake');
}

export function isArticle(url: string): boolean {
    if (!url) {
        return false;
    }

    var params = url.split('-');

    if (parseInt(params[0]) > 0 && params.length > 1) {
        return true;
    }

    return false;
}

export function getTranslateValues(element: Element) {
    const style = window.getComputedStyle(element)
    const matrix = style.transform

    // No transform property. Simply return 0 values.
    if (matrix === 'none') {
        return {
            x: 0,
            y: 0,
            z: 0
        }
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d'
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
        return {
            x: parseInt(matrixValues[4]),
            y: parseInt(matrixValues[5]),
            z: parseInt(style.zIndex)
        }
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
        return {
            x: parseInt(matrixValues[12]),
            y: parseInt(matrixValues[13]),
            z: parseInt(matrixValues[14])
        }
    }
}

export function calculateAspectRatioFit(srcWidth:number, srcHeight:number, maxWidth:number, maxHeight:number) {

    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth*ratio, height: srcHeight*ratio };
 }