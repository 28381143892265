

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { defaultUser, User } from "../types/User";
import Home from "./components/home/Home";
import "bootstrap/dist/css/bootstrap.css";
import Header from './components/header/Header';

import Api from './core/Api';
import { Register } from './components/register/Register';
import Client from './components/client/Client';
import Hotel from './components/hotel/Hotel';
import NotFound from './components/notfound/NotFound';
import { checkInput, inputError } from "./util/InputUtil";
import Cookies from 'universal-cookie';

import swal from 'sweetalert2';
import i18n from "../../i18n/Config";
import { Loader } from "./components/loader/Loader";
import Articles from "./components/articles/Articles";
import Gallery from "./components/gallery/Gallery";

import FingerprintJS from '@fingerprintjs/fingerprintjs'


type AtlantaAppState = {
    user: User,
    error: string,
    registerIsOpen: boolean,
    clientIsOpen: boolean,
    isLoading: boolean
}

const AtlantaAppDefaultState: AtlantaAppState = {
    user: defaultUser,
    error: null,
    registerIsOpen: false,
    clientIsOpen: false,
    isLoading: true
}

const cookies = new Cookies();

class AtlantaApp extends Component<{}, AtlantaAppState>{

    private static instance: AtlantaApp;

    public static getInstance(): AtlantaApp {
        return AtlantaApp.instance;
    }

    private sended: boolean = false;

    public fingerPrint: string;

    constructor(props: {}) {
        super(props);
        this.state = AtlantaAppDefaultState;
        AtlantaApp.instance = this;
    }

    setUser(user: User) {
        this.setState({
            user,
            isLoading: false
        })
    }

    async componentDidMount() {

        Api.getInstance();

        const fpPromise =  await FingerprintJS.load();


        const result = await fpPromise.get();
        this.fingerPrint = result.visitorId;

        this.reloadUser().catch(
            e => this.setState({ error: e.toString() })
        )

    }

    async reloadUser() {
        this.setState({ isLoading: true });
        const user: User = await Api.getInstance().get('/users/me');

        if (!user || !user.id) {
            this.setUser(defaultUser);
            return;
        }


        this.setUser(user);
    }

    openRegister() {
        this.setState({
            registerIsOpen: true
        })
    }

    openClient(state: boolean) {
        this.setState({
            clientIsOpen: state
        })
    }

    async loginUser(form: React.FormEvent<HTMLFormElement>) {
        form.preventDefault();

        if (this.sended) return;

        const elements: HTMLFormControlsCollection = form.currentTarget.elements;
        const usernameInput = (elements.namedItem("username") as HTMLInputElement);
        const passwordInput = (elements.namedItem("password") as HTMLInputElement);

        let error = false;
        if (checkInput(usernameInput)) {
            inputError(usernameInput.parentNode);
            error = true;
        }

        if (checkInput(passwordInput)) {
            inputError(passwordInput.parentNode);
            error = true;
        }

        if (error) return;

        this.sended = true;
        const result = await Api.getInstance().postEncode('/users/login', { username: usernameInput.value, password: passwordInput.value });

        if (result.reload) {
            await this.reloadUser();
            return;
        }

        if (!result.error) {
            const date = new Date((result.exp * 1000));
            cookies.set('ATLANTA_SSID', result.atlanta_ssid, { expires: date });
            cookies.set('ATLANTA_UID', result.atlanta_uid, { expires: date });
            cookies.set('ATLANTA_STI', result.atlanta_sti, { expires: date });

            await this.reloadUser();
        }
        else {
            if (result.fields) {
                result.fields.forEach((at: string) => {
                    const field = (elements.namedItem(at) as HTMLInputElement);
                    inputError(field.parentNode);
                })
            }

            if (result.banned) {
                const expires = (new Date(result.banExpires*1000)).toLocaleString('default', { month: 'long', year: 'numeric', day: '2-digit' });
                swal.fire({
                    text: i18n.t('login.errors.user_banned', { reason: result.banReason, expire: expires, id: result.banId }),
                    icon: "error",
                });
            }
            else {
                const error = result.error ?? 'unexpected_error';
                swal.fire({
                    text: i18n.t('login.errors.' + error),
                    icon: "error",
                });
            }
        }

        this.sended = false;
    }

    render() {
        const { user, error, registerIsOpen, clientIsOpen, isLoading } = this.state;

        if (error) {
            return (<>{error}</>);
        }

        if (isLoading) return (<Loader />);

        const style = { display: clientIsOpen ? "none" : "block" }

        return (<>
            <Router>
                {user.id > 0 ? <Client isOpen={clientIsOpen} /> : <></>}
                <div style={style}>
                    <Header user={user}></Header>
                    <div className="real-content">
                        <div className="container">
                            <Switch>
                                <Route exact={true} path="/">
                                    <Home user={user} />
                                </Route>
                                {/*<Route exact={true} path="/profile">
                                    <Profile />
        </Route>*/}
                                <Route exact={true} path="/hotel">
                                    {user.id === 0 ? <Redirect to="/" /> : <Hotel />}
                                </Route>
                                <Route path="/community/articles/:title">
                                    <Articles user={user} />
                                </Route>
                                <Route path="/community/articles" exact={true}>
                                    <Articles user={user} />
                                </Route>

                                <Route path="/community/gallery" exact={true}>
                                    <Gallery />
                                </Route>
                                <Route path="*">
                                    <NotFound />
                                </Route>
                            </Switch>
                            {user.id === 0 ? <Register isOpen={registerIsOpen} handleClose={() => { this.setState({ registerIsOpen: false }) }}></Register> : <></>}
                        </div>
                    </div>
                </div>
            </Router>
        </>);
    }
}

export default AtlantaApp;