import React, { FC } from "react";
import { User } from "../../../types/User";
import HeaderLogin from "./subcomponents/HeaderLogin";
import HeaderNav from "./subcomponents/HeaderNav";
import HeaderUser from "./subcomponents/HeaderUser";

type HeaderProps = {
    user: User
}

const Header: FC<HeaderProps> = props => {
    const {
        user
    } = props;
    return (<>
        <div className="main-header">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 users-online">{/*1.581 {t('header.online_users')}*/}</div>
                            {user.id === 0 ? <HeaderLogin /> : <HeaderUser user={user} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HeaderNav user={user} />
    </>)
}

export default Header;