import React, { FC } from "react";
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import esStrings from 'react-timeago/lib/language-strings/es';




const defaultFormatter = buildFormatter(esStrings)
defaultFormatter.suffix = 'ago';

type TimeAgoSectionProps = {
    date: number | Date;
}

const TimeAgoSection: FC<TimeAgoSectionProps> = props => {
    const {
        date
    } = props;

    return (
        <>
            <TimeAgo date={date} formatter={defaultFormatter} />
        </>
    )
}

export default TimeAgoSection;