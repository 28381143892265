import React, { CSSProperties, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArticleCategory, ArticleList } from "../../../../types/Articles";
import TimeAgoSection from "../../../sections/TimeAgoSection";

type ArticleCatsProps = {
    cats: ArticleCategory[];
    articles: ArticleList[];
    boxSize: number;
    updateCategory: (catId: number) => void;
}

const ArticleCats = (props: ArticleCatsProps) => {
    const {
        cats,
        articles,
        boxSize,
        updateCategory
    } = props;

    const {
        t
    } = useTranslation();

    const changeCat = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        updateCategory(parseInt(event.target.value));
    }, [updateCategory]);

    const style: CSSProperties = (boxSize === 4 ? {float: 'right'} : {});

    return (<>
        <div className={`col-${boxSize}`} style={style}>
            <div className="input-group" style={{ float: 'right', width: '120px' }}>
                <select className="custom-select" id="articles-category" onChange={changeCat}>
                    <option key={0} value={0}>{t('pages.home.articles.categories.default_cat')}</option>
                    {
                        cats.map(item => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                </select>
            </div>
            <h4>{t('pages.home.articles.categories.title')}</h4>
            <div className="list-group articles">
                {
                    articles.map(item => (
                        <Link key={item.link} to={`/community/articles/${item.link}`} className="list-group-item list-group-item-action hall-item">
                            <div className="image-window" style={{ background: `url(${item.headerImage}) center` }} />
                            <small className="text-muted" style={{ float: 'right' }}><TimeAgoSection date={(item.time * 1000)} /></small>
                            <div className="title">{item.title}</div>
                            <div className="desc">{item.summary}</div>
                        </Link>
                    ))
                }
            </div>
        </div>

    </>)
}

export default ArticleCats;