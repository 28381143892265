import React, { useCallback } from 'react';
import { useTranslation } from "react-i18next";
import AtlantaApp from '../../../AtlantaApp';

export const LoginHome = () => {
    const sendLogin = useCallback((form: React.FormEvent<HTMLFormElement>) => {
        return AtlantaApp.getInstance().loginUser(form);
    },[]);
    const { t } = useTranslation();
    return (<>
        <div className="row d-block d-sm-block d-md-none">
            <div className="col-sm-12" style={{ marginBottom: '20px', borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '20px' }}>
                <h3 className="login-heading-m">{t('login.login_text')}</h3>
                <form method="post" onSubmit={sendLogin}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-group large mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-user" /></span>
                                </div>
                                <input type="text" className="form-control" name="username" placeholder={t('login.form.username')} />
                            </div>
                        </div>
                        <div className=" col-sm-6">
                            <div className="input-group large mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-lock" /></span>
                                </div>
                                <input type="password" className="form-control" name="password" placeholder={t('login.form.password')} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <a className="forgot-password-m" href="/TTt">{t('login.form.forgot_password')}</a>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <button type="submit" className="rounded-button purple-button no-border" style={{ float: 'right' }}>{t('login.form.button')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>);
}