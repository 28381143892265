import 'core-js';
import React from 'react';
import { render }  from 'react-dom';
import './index.css';
import AtlantaApp from './atlanta/web/AtlantaApp';

import './i18n/Config';

import './styles/AtlantaApp.scss';

const rootElement = document.getElementById("AtlantaApp");
if(rootElement)
    render(<AtlantaApp />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
