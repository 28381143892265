import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AtlantaApp from '../../../AtlantaApp';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
const HeaderLogin: FC<{}> = _ => {

    const { t } = useTranslation();

    const openRegister = useCallback(() => {
        AtlantaApp.getInstance().openRegister();
    }, []);
    const sendLogin = useCallback((form: React.FormEvent<HTMLFormElement>) => {
        return AtlantaApp.getInstance().loginUser(form);
    }, []);

    const ref = useRef<HTMLImageElement>();

    const {width} = useWindowDimensions();

    const adjustLogo = useCallback(() => {
        if(!ref || !ref.current) return;
        const image = ref.current;
        if(image.naturalWidth === 0) return;
        if((width-20) > image.naturalWidth){
            image.setAttribute('style', `width: ${image.naturalWidth}px; height: ${image.naturalHeight}px`)
        }
        else{
            image.setAttribute('style', `width: 100%; height: 100%`)
        }
    }, [ref, width])

    useEffect(() => {
        adjustLogo();
    }, [width, adjustLogo])

    return (<>
        <div className="col-md-12 col-sm-12" style={{ textAlign: 'center' }}>
            <Link to={"/"}>
                <img className="logo" ref={ref} alt="logo" src="/images/logo.gif" />
            </Link>
        </div>
        <div className="col-md-6 col-sm-12" style={{ textAlign: 'center' }}>
            <div className="sub-logo">{t('header.text_1')}<br />{t('header.text_2')}<br />{t('header.text_3')}</div>
            <button onClick={openRegister} className="rounded-button large-button default-cms-button">{t('login.register')}</button>
        </div>
        <div className="col-md-6 col-sm-12 d-none d-sm-none d-md-block">
            <h3 className="login-heading">{t('login.login_text')}</h3>
            <form method="post" onSubmit={sendLogin}>
                <div className="row" style={{ marginBottom: 0 }}>
                    <div className="col-md-6 col-sm-6">
                        <div className="input-group large">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-user" /></span>
                            </div>
                            <input type="text" name="username" className="form-control" placeholder={t('login.form.username')} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="input-group large">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-lock" /></span>
                            </div>
                            <input type="password" name="password" className="form-control" placeholder={t('login.form.password')} />
                        </div>
                    </div>
                </div>
                <a className="forgot-password" href="/tTT" data-toggle="modal" data-target="#forgotpass-modal">{t('login.form.forgot_password')}</a><br />
                <button type="submit" className="rounded-button default-cms-button" style={{ float: 'right' }}>{t('login.form.button')}</button>
            </form>
        </div>
    </>)
}

export default HeaderLogin;