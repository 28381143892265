import React, { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HOTEL } from "../../../../config/WebConfig";
import AtlantaApp from "../../../AtlantaApp";

const BigJumbotron: FC<{}> = props => {
    const {
        t
    } = useTranslation();

    const openRegister = useCallback(() => {
        AtlantaApp.getInstance().openRegister();
    }, []);
    return (<>
        <div className="dark-area" style={{ position: "absolute", left: 0, right: 0 }}>
            <div className="jumbotron" style={{ textAlign: "center" }}><br />
                <h1 className="display-4">
                    <Trans t={t} i18nKey="pages.home.jumbotron.title">
                        ¡{{ name: HOTEL.SHORTNAME }} es gratuito!
                    </Trans>
                </h1>

                <p className="lead">
                    <Trans t={t} i18nKey="pages.home.jumbotron.lead">
                        {{ name: HOTEL.FULLNAME }} es un juego online donde puedes explorar cuartos, hacer amigos y ganar premios sin pagar nada! ¿Qué estás esperando para ser parte de esta gran comunidad?
                    </Trans>
                </p>
                <br /><button onClick={openRegister} className="rounded-button large-button purple-button">{t('pages.home.jumbotron.button')}</button>
            </div>
        </div>
    </>)
}

export default BigJumbotron;