import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Article } from "../../../../types/Articles";
import { User } from "../../../../types/User";
import Api from "../../../core/Api";
import TimeAgoSection from "../../../sections/TimeAgoSection";
import { avatar } from "../../../util/FigureUtil";
import { calculateAspectRatioFit } from "../../../util/InputUtil";

type ArticleBodyProps = {
    article: Article;
    user: User;
}

const LIKE_EVENT: number = 0;
const DISLIKE_EVENT: number = 1;

const fields = { 0: "likes", 1: "dislikes" };

const ArticleBody: FC<ArticleBodyProps> = props => {
    const {
        article,
        user
    } = props;

    const [onLike, setOnLike] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const articleRef = useRef<HTMLDivElement>(null);

    const [likeType, setLikeType] = useState(article.likeType);
    const [likesCount, setLikesCount] = useState<{ likes: number, dislikes: number }>({ likes: article.likeCount, dislikes: article.disLikeCount });

    const likeEvent = useCallback(async (type: number, type2: number) => {
        if (onLike || likeType === type || user.id < 1) return;
        setOnLike(true);

        const result: { success: boolean } = await Api.getInstance().post("/articles/likearticle", { articleId: article.id, type, token: article.key });

        if (result && result.success) {
            if (likeType === type2) {
                likesCount[fields[type2]] = (likesCount[fields[type2]]-1);
            }
            likesCount[fields[type]] = (likesCount[fields[type]]+1);

            setLikesCount(likesCount);

            setLikeType(type);
        }
        setOnLike(false);
    }, [user, onLike, setOnLike, likeType, setLikesCount, setLikeType, likesCount, article])

    const like = useCallback(async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        await likeEvent(LIKE_EVENT, DISLIKE_EVENT);
    }, [likeEvent])


    const resizeImages = useCallback(() => {
        if (!ref || !ref.current) return;
        const images: HTMLCollectionOf<HTMLImageElement> = (document.getElementsByTagName("img"));
        Array.from(images).forEach((item) => {
            if (item.clientWidth > (ref.current.clientWidth - 20)) {
                const t = calculateAspectRatioFit(item.clientWidth, item.clientHeight, ref.current.clientWidth - 20, ref.current.clientHeight);
                item.setAttribute('style', `width:${t.width}px; height:${t.height}px`);
            }
        });
    }, [ref]);

    const disLike = useCallback(async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        await likeEvent(DISLIKE_EVENT, LIKE_EVENT);
    }, [likeEvent]);

    useEffect(() => {
        setLikeType(article.likeType);
        setLikesCount({ likes: article.likeCount, dislikes: article.disLikeCount });
        resizeImages();
    }, [article, setLikeType, setLikesCount, resizeImages])


    useEffect(() => {
        window.addEventListener('resize', resizeImages);

        return () => {
            window.removeEventListener('resize', resizeImages);
        }
    }, [resizeImages])

    return (<>
        <div className="col-md-8 col-sm-12"  ref={articleRef}>
            <h4 >{article.title}</h4>
            <div className="card">
                <div className="heading" style={{ backgroundImage: `url(${article.headerImage})` }}>
                    <span className="badge badge-dark">{article.category.name}</span>
                    <div className="writer" style={{ backgroundImage: `url(${avatar(article.user.look, { size: 'l', head_direction: 3, gesture: 'sml' })})` }} />
                    <div className="information">
                        Publicado por <Link to={`/profile/${article.user.username}`}>{article.user.username}</Link>
                        <span className="d-none d-sm-none d-md-block" style={{ float: 'right' }}><i className="far fa-clock" /> {" "}<TimeAgoSection date={(article.time * 1000)} /></span>

                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    <TimeAgoSection date={(article.time * 1000)} />
                                </Tooltip>
                            }
                        >
                            <span className="d-sm-block d-md-none" style={{ float: 'right', fontSize: '18px' }}><i className="far fa-clock" /></span>
                        </OverlayTrigger>

                    </div>
                </div>
                <div className="card-body" ref={ref} dangerouslySetInnerHTML={{ __html: article.body }}>
                </div>
                <div className="options">
                    <a href="/" onClick={like}><i className="fas fa-thumbs-up" /> Me gusta ({likesCount.likes})</a>
                    <a href="/" onClick={disLike}><i className="fas fa-thumbs-down" /> No me gusta ({likesCount.dislikes})</a>
                    <i className="far fa-eye" /> Visualizaciones ({article.views})
                </div>
            </div>
            <br />
        </div>

    </>)
}
export default ArticleBody;